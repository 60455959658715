<template>
  <!-- 头部整体盒子 -->
  <div class="header_box">
    <div id="header" class="container-fuild" :class="{}">
      <!-- 头部顶部 -->
      <div v-if="false" class="header-top container-fuild hidden-xs d-none d-sm-block">
        <div class="container">
          <div class="server pull-left">
            <span class="glyphicon glyphicon-earphone"></span>888-888-888
            <span class="glyphicon glyphicon-envelope"></span>liyunkun_11@163.com
            <span class="glyphicon glyphicon-time"></span>7x24小时为您服务
          </div>
          <div class="shejiao pull-right">
            <span class="glyphicon glyphicon-hand-right"></span>赶快联系我们吧！
            <span class="glyphicon glyphicon-hand-left"></span>
          </div>
        </div>
      </div>
      <!-- 电脑导航 -->
      <div class="header-nav d-none d-sm-block hidden-xs">
        <!-- 导航logo -->
        <div class="header_left">
          <div class="header-nav-logo">
            <img src="@/assets/img/logo.png" />
          </div>
          <!-- 导航内容 -->
          <ul class="header-nav-wrapper">
            <li
              v-for="(item, index) in navList"
              :key="index"
              :class="index == navIndex ? 'active' : ''"
              @click="navClick(index, item.name)"
            >
              <router-link :to="item.path">
                {{ item.name }}
                <span v-if="item.children.length > 0" class="bi bi-menu-down"></span>
                <i class="underline"></i>
              </router-link>
              <dl v-if="item.children.length > 0">
                <dt v-for="(i, n) in item.children" :key="n">
                  <router-link :to="i.path">{{ i.name }}</router-link>
                </dt>
              </dl>
            </li>
          </ul>
        </div>

        <div class="header_button_box">
          <div class="hbb_btn">
            <a href="https://yykt100.com/web/" target="_blank">登录</a>
          </div>
        </div>
      </div>
      <!-- 手机导航 -->
      <div class="header-nav-m container-fuild d-block d-sm-none visible-xs">
        <div class="header-nav-m-logo">
          <img class="center-block" src="@/assets/img/logo.png" alt="logo" />
        </div>
        <!-- 导航栏 -->
        <div class="header-nav-m-menu text-center">
          {{ menuName }}
          <div
            class="header-nav-m-menu-wrapper navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#menu"
            @click="menuClick"
          >
            <!-- <span :class="menuClass"></span> -->
            <span class="bi bi-list" style="font-size: 23px"></span>
          </div>
          <!-- 导航内容 -->
          <ul id="menu" class="header-nav-m-wrapper collapse">
            <li
              v-for="(item, index) in navList"
              :key="index"
              :class="index == navIndex ? 'active' : ''"
              @click="navClick(index, item.name)"
              data-toggle="collapse"
              data-target="#menu"
            >
              <router-link :to="item.path">
                {{ item.name }}
                <i class="underline"></i>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderVue",
  data() {
    return {
      navIndex: sessionStorage.getItem("navIndex")
        ? sessionStorage.getItem("navIndex")
        : 0,
      menuName: "首页",
      menuClass: "bi bi-menu-down",
      navList: [
        {
          name: "首页",
          path: "/",
          children: [],
        },
        /*  {
          name: "解决方案",
          // path: "/solution",
          path: "",
          children: [],
        }, */
        // {
        //   name: "解决方案",
        //   path: "/software",
        //   // children: [
        //   //   {
        //   //     name: "智能小镇管理系统",
        //   //     path: "/software/smartTown",
        //   //   },
        //   //   {
        //   //     name: "大数据管理系统",
        //   //     path: "/software/bigData",
        //   //   },
        //   // ],
        // },
        /*  {
          name: "新闻资讯",
          // path: "/service",
          path: "",
          children: [],
        }, */
        /*   {
          name: "客户服务",
          // path: "/newsinformation",
          path: "",
          children: [],
        }, */
        // {
        //   name: "公司介绍",
        //   path: "/companyintroduction",
        //   children: [],
        // },
        // {
        //   name: "工作机会",
        //   path: "/jobchance",
        //   children: [],
        // },
        /*  {
          name: "联系我们",
          // path: "/contactus",
          path: "",
          children: [],
        }, */
      ],
    };
  },
  methods: {
    navClick(index, name) {
      this.navIndex = index;
      sessionStorage.setItem("navIndex", index);
      this.menuName = name;
    },
    menuClick() {
      if (this.menuClass == "bi bi-menu-down") {
        this.menuClass = "bi bi-menu-up";
      } else {
        this.menuClass = "bi bi-menu-down";
      }
    },
  },
  created() {
    // console.log(this.$route, "11111111111");
  },
};
</script>
<style scoped lang="scss">
.header_box {
  width: 100%;
  background: #fff;
  position: sticky;
  top: 0px;
  z-index: 200;
}
/* 顶部 */
#header {
  background: #fff;
  transition: all ease 0.6s;
  max-width: 1400px;
  margin: auto;
  @media screen and (min-width: 576px) {
    .header-nav {
      display: flex !important;
    }
  }
  .header_left {
    display: flex;
    flex: auto;
    align-items: center;
    height: 100%;
  }
  .header-nav-logo {
  }
  .header_button_box {
    height: 100%;
    display: flex;
    flex: none;
    position: relative;
    justify-content: center;
    align-items: center;
    .hbb_btn {
      background-color: #4cabff;
      color: #fff;
      border-radius: 5px;
      padding: 5px 20px;
      &:hover {
        background-color: #79bbff;
      }
      cursor: pointer;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
#header .header-top {
  height: 50px;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  background: #474747;
}
/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}
/* 导航栏 */
#header .header-nav {
  height: 70px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
}
/* 导航栏logo */
#header .header-nav .header-nav-logo {
  // width: 180px;
  flex: 0 0 18%;
  height: 100%;
  max-width: 179px;
  // float: left;
  position: relative;
}
/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  // width: 180px;
  width: auto;
  /* height: 45px; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
}
/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}
#header .header-nav .header-nav-wrapper {
  line-height: 70px;
  flex: auto;
  flex-shrink: 0;
  // float: left;
  margin: 0;
  max-width: 800px;
  margin-left: 2%;
}
/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper > li {
  float: left;
  margin: 0 15px;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper > li > a {
  color: #000;
  font-size: 15px;
  font-weight: bold;
  padding: 15px 0;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper > li > a > i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #1e73be;
}
/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper > li > a > span {
  font-size: 12px;
  transition: transform ease 0.5s;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover {
  color: #1e73be;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover span {
  transform: rotate(180deg);
}
/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper > li.active > a {
  color: #1e73be;
  text-decoration: none;
  border-bottom: 2px solid #1e73be;
}
/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper > li > dl {
  display: none;
  position: absolute;
  width: 168px;
  top: 80%;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 3px 1px #ccc;
  background: #fff;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper > li > dl > dt {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper > li > dl > dt > a:hover {
  text-decoration: none;
}
/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper > li:hover dl {
  display: block;
}
#header .header-nav .header-nav-wrapper > li > dl > dt:hover {
  cursor: pointer;
  background: #ccc;
}
@media screen and (min-width: 768px) {
  .header-nav {
    padding: 0 12px 0 12px;
  }
}
@media screen and (max-width: 997px) {
  #header .header-nav-m {
    position: relative;
  }
  /* 导航栏logo容器 */
  #header .header-nav-m .header-nav-m-logo {
    height: 80px;
    position: relative;
  }
  /* 导航栏logo图片 */
  #header .header-nav-m .header-nav-m-logo img {
    // width: 95px;
    width: auto;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  /* 导航栏  菜单容器 */
  #header .header-nav-m .header-nav-m-menu {
    color: #fff;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    background: #474747;
    position: relative;
  }
  /* 导航栏 菜单图标 */
  #header .header-nav-m .header-nav-m-menu-wrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    width: 50px;
    height: 40px;
    color: #fff;
    z-index: 999999;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* 导航栏 */
  #header .header-nav-m .header-nav-m-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background: #474747;
    z-index: 9999999;
  }
  /* 导航栏 每个导航 */
  #header .header-nav-m .header-nav-m-wrapper > li {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
  }
  /* 导航栏 每个导航下面的 a 链接 */
  #header .header-nav-m .header-nav-m-wrapper > li > a {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 0;
    position: relative;
  }
  /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
  #header .header-nav .header-nav-wrapper > li > a > span {
    font-size: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .header-nav {
    padding: 0 32px;
    max-width: 1170px;
  }
}
</style>
